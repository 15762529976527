import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {NavbarClusterComponent} from './navbarcluster/navbarcluster.component';
import {SpinnerComponent} from './spinner/spinner.component';
import { NavbarAssignUserComponent } from './navbarassignuser/navbarassignuser.component';
import { NavbarMeasurerComponent } from './navbarmeasureruser/navbarmeasureruser.component';

import { NavbarGmComponent } from './navbargm/navbargm.component';
import { NavbarAuditerComponent } from './navbarauditer/navbarauditer.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    NavbarClusterComponent,
    NavbarAssignUserComponent,
    NavbarMeasurerComponent,
    NavbarAuditerComponent,
    NavbarGmComponent,
    SpinnerComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    NavbarClusterComponent,
    NavbarGmComponent,
    NavbarAuditerComponent,
    NavbarAssignUserComponent,
    NavbarMeasurerComponent,
    SpinnerComponent
  ]
})
export class ComponentsModule { }
