import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { ClusterLayoutComponent } from "./layouts/cluster-layout/cluster-layout.component";
import { AuthGuard } from "../app/services/auth.gurds";
import { LoginComponent } from "./login/login.component";
import { AssignUserLayoutComponent } from "./layouts/assignuser-layout/assignuser-layout.component";
import { MeasurerLayoutComponent } from "./layouts/measurer-layout/measurer-layout.component";

import { GmLayoutComponent } from "./layouts/gm-layout/gm-layout.component";
import { AudtierLayoutComponent } from "./layouts/auditer-layout/auditer-layout.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/auth/auth-layout.module').then(m => m.AuthLayoutModule)
      },
    ],
  },
  {
    path: "admindashboard",
    redirectTo: "admindashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {roles: ["admin"]},
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      },
    ],
  },
  {
    path: "dashboard",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    data: {roles: ["admin","cluster","ceo"]},
    component: ClusterLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/cluster-layout/cluster-layout.module').then(m => m.ClusterLayoutModule)
      },
    ],
  },
  {
    path: "dashboard-auditer",
    redirectTo: "dashboard-auditer",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    data: {roles: ["admin","auditer"]},
    component: AudtierLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/auditer-layout/auditer-layout.module').then(m => m.AuditerLayoutModule)
      },
    ],
  },
  {
    path: "dashboard-gm",
    redirectTo: "dashboard-gm",
    pathMatch: "full",
  },
  {
    path: "",
    canActivate: [AuthGuard],
    data: {roles: ["admin","gm"]},
    component: GmLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/gm-layout/gm-layout.module').then(m => m.GmLayoutModule)
      },
    ],
  },
  {
    path: "assignuserdashboard",
    redirectTo: "assignuserdashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AssignUserLayoutComponent,
    canActivate: [AuthGuard],
    data: {roles: ["admin","assign"]},
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/assignuser-layout/assignuser-layout.module').then(m => m.AssignUserLayoutModule)
      },
    ],
  },
  
  {
    path: "measurerdashboard",
    redirectTo: "measurerdashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: MeasurerLayoutComponent,
    canActivate: [AuthGuard],
    data: {roles: ["admin","measurer","auditer"]},
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/measurer-layout/measurer-layout.module').then(m => m.MeasurerLayoutModule)
      },
    ],
  },
  {
    path: "**",
    redirectTo: "login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule {}
