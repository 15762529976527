import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class Globals {
  
  pageSize: number = 10;
  pageNumber: number = 1;
  totalPages: number;
  
  url = 'http://localhost:3200/';
}

