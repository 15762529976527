import { Injectable,NgZone} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';


//import the auth service
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private AuthService: AuthService, private router: Router,private ngzone:NgZone){}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = JSON.parse(localStorage.getItem("currentuser"));
    console.log(currentUser);
    if(currentUser){
      //check if the route is retricted by role
      console.log( next.data.roles);
      
      if(next.data.roles && next.data.roles.indexOf(currentUser.role) === -1){
        //role not authorized
        this.ngzone.run(() => {
          this.router.navigateByUrl('/login');
        });
      }else{
        return true;
      }
    }
    else {
      this.ngzone.run(() => {
        this.router.navigateByUrl('/login');
      });
    }
    
  }
}