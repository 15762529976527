


import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../services/auth.service";

declare const $: any;
interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: RouteInfo[]; // Add children property
}
export const ROUTES: RouteInfo[] = [

    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/analysis', title: 'Analysis',  icon: 'bar_chart', class: '' },
    { path: '/actionplan', title: 'Action Plan',  icon: 'chat', class: '' },
    { path: '/addBranches', title: 'Branches',  icon: 'hub', class: '' },
      
  

      {
        title: 'Docments',
        icon: 'attach_file',
        class: 'nav-item-dropdown',
        path:"",
        children: [
          { path: '/reports', title: 'Audit Reports', icon: 'content_paste', class: '' },
          { path: '/downloads', title: 'Forms', icon: 'file_copy', class: '' },
    
        ]
    },
      {
        title: 'Lab',
        icon: 'science',
        class: 'nav-item-dropdown',
        path:"",
        children: [
          { path: '/testresults', title: 'Add Test Result', icon: 'biotech', class: '' },
          { path: '/labreport', title: 'Reports', icon: 'summarize', class: '' }
    
        ]
    },
      {
          title: 'Measurers',
          icon: 'device_thermostat',
          class: 'nav-item-dropdown',
          path:"",
          children: [
              { path: '/measurerreports-cluster', title: 'Reports', icon: 'summarize', class: '' }
          ]
      },
      {
        title: 'Users',
        icon: 'people',
        class: 'nav-item-dropdown',
        path:"",
        children: [
          { path: '/addcluster', title: 'Add Cluster',  icon: 'manage_accounts', class: '' },
          { path: '/addceo', title: 'Add CEO',  icon: 'manage_accounts', class: '' },
          { path: '/addgm', title: 'Add GM',  icon: 'manage_accounts', class: '' },
          { path: '/addauditer', title: 'Add Auditor',  icon: 'manage_accounts', class: '' },
          { path: '/addassigner', title: 'Add Assigner',  icon: 'manage_accounts', class: '' },
          { path: '/addusermeasurer-cluster', title: 'Add Measurer',  icon: 'manage_accounts', class: '' },    
        ]
    }


    ];

@Component({
  selector: 'app-navbarcluster',
  templateUrl: './navbarcluster.component.html',
  styleUrls: ['./navbarcluster.component.css']
})
export class NavbarClusterComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router,private ngzone:NgZone,private AuthService:AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES;
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  toggleDropdown(menuItem: any) {
    menuItem.open = !menuItem.open;
}

GotoChangePassword(){
  this.router.navigateByUrl('/changepassword');

}
logout() {
this.AuthService.logout();
}
}

