import { Component, OnInit } from '@angular/core';
import {LoaderServiceService} from "../../services/loaderService.service";
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  isLoading$ = this.LoaderServiceService.isLoading$;

  constructor(private LoaderServiceService: LoaderServiceService) {}

    ngOnInit() {

    }




}
