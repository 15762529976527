


import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from "../../services/auth.service";

declare const $: any;
interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: RouteInfo[]; // Add children property
}

// export const ROUTES: RouteInfo[] = [

//     { path: '/dashboard-auditer', title: 'Dashboard',  icon: 'dashboard', class: '' },
//     { path: '/analysis-auditer', title: 'Analysis',  icon: 'bar_chart', class: '' },
//     { path: '/reports-auditer', title: 'Reports',  icon: 'content_paste', class: '' },
//     { path: '/downloads-auditer', title: 'Downaloads',  icon: 'file_copy', class: '' },
//     { path: '/actionplan-auditer', title: 'Action Plan',  icon: 'chat', class: '' },
//     { path: '/addassignuser-auditer', title: 'Add Assign User',  icon: 'supervised_user_circle', class: '' },
//     { path: '/labreport-auditer', title: 'Lab Reports',  icon: 'supervised_user_circle', class: '' },
//     { path: '/measurerdashboard-auditer', title: 'measurers',  icon: 'assignment_turned_in', class: '' },
//     { path: '/addmeasureitems-auditer', title: 'Add Measure Items',  icon: 'assignment_turned_in', class: '' },
//     { path: '/addusermeasurer-auditer', title: 'Add User Measurer',  icon: 'assignment_turned_in', class: '' },
//     { path: '/measurerreports-auditer', title: 'Measurer Reports',  icon: 'assignment_turned_in', class: '' }


// ];

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard-auditer', title: 'Dashboard', icon: 'dashboard', class: '' },
  { path: '/analysis-auditer', title: 'Analysis', icon: 'bar_chart', class: '' },
  { path: '/actionplan-auditer', title: 'Action Plan', icon: 'chat', class: '' },
  {
    title: 'Docments',
    icon: 'attach_file',
    class: 'nav-item-dropdown',
    path:"",
    children: [
      { path: '/reports-auditer', title: 'Audit Reports', icon: 'content_paste', class: '' },
      { path: '/downloads-auditer', title: 'Forms', icon: 'file_copy', class: '' },

    ]
},
  {
    title: 'Lab',
    icon: 'science',
    class: 'nav-item-dropdown',
    path:"",
    children: [
      { path: '/testresults-auditer', title: 'Add Test Result', icon: 'biotech', class: '' },
      { path: '/labreport-auditer', title: 'Reports', icon: 'summarize', class: '' }

    ]
},
  {
      title: 'Measurers',
      icon: 'device_thermostat',
      class: 'nav-item-dropdown',
      path:"",
      children: [
        { path: '/addmeasureitems-auditer', title: 'Add Measure Items', icon: 'kitchen', class: '' },
        { path: '/measurerdashboard-auditer', title: 'Add Measure', icon: 'thermostat_auto', class: '' },
          { path: '/measurerreports-auditer', title: 'Reports', icon: 'summarize', class: '' }

      ]
  },
  {
    title: 'Users',
    icon: 'people',
    class: 'nav-item-dropdown',
    path:"",
    children: [
      { path: '/addassignuser-auditer', title: 'Add Assign User', icon: 'manage_accounts', class: '' },
      { path: '/addusermeasurer-auditer', title: 'Add Measurer', icon: 'manage_accounts', class: '' }

    ]
}
];


@Component({
  selector: 'app-navbarauditer',
  templateUrl: './navbarauditer.component.html',
  styleUrls: ['./navbarauditer.component.css']
})
export class NavbarAuditerComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router,private ngzone:NgZone,private AuthService:AuthService) { }

  ngOnInit() {
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.menuItems = ROUTES;
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

  toggleDropdown(menuItem: any) {
    menuItem.open = !menuItem.open;
}
GotoChangePassword(){
  this.router.navigateByUrl('/changepassword');

}

logout() {
this.AuthService.logout();


}
}

