import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/admindashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/addnewclient', title: 'Clients',  icon: 'person', class: '' },
    { path: '/adddocument', title: 'Add Docments',  icon: 'content_paste', class: '' },
    { path: '/Adddepartment', title: 'Add Departemnts',  icon: 'content_paste', class: '' },
    { path: '/addpackage', title: 'Add Package',  icon: 'inventory_2', class: '' },
    { path: '/addmodule', title: 'Add Module',  icon: 'policy', class: '' },

    
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
