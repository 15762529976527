import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-measurer-layout',
  templateUrl: './measurer-layout.component.html',
  styleUrls: ['./measurer-layout.component.scss']
})
export class MeasurerLayoutComponent implements OnInit {


  constructor() {}

  ngOnInit() {
  }


}
