export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyCc5U3Ex3C41iJfKc5Yq-D0mcvxm7aiIIM",
    authDomain: "i-monitor-19ba2.firebaseapp.com",
    databaseURL: "https://i-monitor-19ba2.firebaseio.com",
    projectId: "i-monitor-19ba2",
    storageBucket: "i-monitor-19ba2.appspot.com",
    messagingSenderId: "368611381248",
    appId: "1:368611381248:web:77225df9ad80c02bfcc8e5",
    measurementId: "G-YDSG0KC2Z5"
  }
};
