import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {AuthService} from "./auth.service";
import Swal from 'sweetalert2';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private AuthService:AuthService,private router: Router, private afAuth: AngularFireAuth) {}

  handleError(error: any): void {      
    const  errorJson  = this.isFirebasePermissionDenied(error);
    if (errorJson.error.stack[1] == "FirebaseError: Missing or insufficient permissions."){
    this.logout();
    } else {
      console.error('An error occurred:', error); // Log other errors
    }
  }

  private async logout() {
    Swal.fire({
        icon: "error",
        title: "Oops...",
        text: 'Subscription Renewal Needed'
      })
    this.AuthService.logout();
  }
  private isFirebasePermissionDenied(error: any): any {
    // Check if it's a Firebase error with permission-denied
    const errorJson = {
        error: {
          code: error.code || 'unknown',
          message: error.message || 'An unknown error occurred.',
          stack: error.stack ? error.stack.split('\n') : []
        }
      };
      
      console.error(JSON.stringify(errorJson, null, 2));

    return errorJson;
  }
}