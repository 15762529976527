import { Component} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { GeneralService } from '../app/services/general.service';
import {AuthService} from '../app/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private AngularFirestore:AngularFirestore,
    private AuthService:AuthService
  ) { 

  const DbName=  localStorage.getItem('DbName')
console.log(DbName)
if(DbName){
  this.AngularFirestore.firestore['_databaseId'].database=DbName
  
}
  }
  
}
