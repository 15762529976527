import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-assignuser-layout',
  templateUrl: './assignuser-layout.component.html',
  styleUrls: ['./assignuser-layout.component.scss']
})
export class AssignUserLayoutComponent implements OnInit {


  constructor() {}

  ngOnInit() {
  }


}
