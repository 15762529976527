<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
            <ul>
                <li>
                    <a href="">
                        I-Monitor
                    </a>
                </li>
                <!-- <li>
                    <a href="https://creative-tim.com/about-us">
                        About Us
                    </a>
                </li>
                <li>
                    <a href="http://blog.creative-tim.com">
                        Blog
                    </a>
                </li> -->
                <!-- <li>
                    <a href="https://www.creative-tim.com/license">
                        Licenses
                    </a>
                </li> -->
            </ul>
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}}, made by
            <a href="" target="_blank">I-Monitor</a> Ensuring Excellence , Every Inspection .
        </div>
    </div>
</footer>
