import { Injectable, NgZone } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Swal from "sweetalert2";
import { LoaderServiceService } from './loaderService.service';
import { HttpClient } from '@angular/common/http';
import { getFirestore, FieldValue, Timestamp } from "firebase/firestore";
import { environment } from "environments/environment";
import { getIdTokenResult } from "firebase/auth";


@Injectable({
  providedIn: "root"
})
export class AuthService {
  public currentUser: any;
  public userStatus: string;

  public userStatusChanges: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private firestore: AngularFirestore,
    private ngzone: NgZone,
    private LoaderServiceService: LoaderServiceService,
    private http: HttpClient,
  ) { }

  setUserStatus(userStatus: any): void {
    this.userStatus = userStatus;
    this.userStatusChanges.next(userStatus);
  }

  deployIndexes(dbname: string) {
    const functionUrl = `https://us-central1-i-monitor-19ba2.cloudfunctions.net/deployIndexes?dbname=${dbname}`;

    return this.http.get(functionUrl).toPromise();
  }

  userChanges() {
    this.LoaderServiceService.show(); // Show the loader before making the request
    this.afAuth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const userRole = JSON.parse(localStorage.getItem("currentuser"));
        console.log(userRole);
        if (userRole) {
          this.ngzone.run(() => {
            if (userRole.passwordChanged) {
              // Navigate based on user role
              if (userRole.role == "cluster" || this.currentUser.role == "ceo") {
                this.router.navigateByUrl("/dashboard");
              } else if (userRole.role == "assign") {
                this.router.navigateByUrl("/assignuserdashboard");
              } else if (userRole.role == "gm") {
                this.router.navigateByUrl("/dashboard-gm");
              } else if (userRole.role == "auditer") {
                this.router.navigateByUrl("/dashboard-auditer");
              }
              else if (userRole.role == "measurer") {
                this.router.navigateByUrl("/measurerdashboard");
              }
              else {
                this.router.navigate(["/admindashboard"]);
              }


            }
            else {
              this.router.navigate(["/change-password"]);
            }
          });
        }
      } else {
        const database = localStorage.getItem('DbName')
        this.ngzone.run(() => {
          this.router.navigate([database + "/login"]);
        });
      }
      this.LoaderServiceService.hide(); // Show the loader before making the request

    });
  }

  login(email: string, password: string) {
    this.LoaderServiceService.show(); // Show the loader before making the request
    this.afAuth.signInWithEmailAndPassword(email, password)

    .then(async (userCredential) => {
      console.log("Nice, it worked!");
      console.log(userCredential);
      const user = userCredential.user;
      const tokenResult = await getIdTokenResult(user);
      const databaseName = tokenResult.claims.databaseName;
      console.log(databaseName)
      console.log(user.email)
      if(databaseName){
        localStorage.setItem('DbName', databaseName as any)
        this.firestore.firestore['_databaseId'].database=databaseName
      }
      else{
        this.firestore.firestore['_databaseId'].database='(default)'
        localStorage.setItem('DbName', '(default)')

       // this.firestore.firestore['_databaseId'].database='(default)'

      }
      
      this.firestore.collection("users").ref.where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // If no user is found with the provided email, show an error or take some action
          this.LoaderServiceService.hide(); // Show the loader before making the request

          Swal.fire({
            title: 'Warning!',
            text: 'No user found with this email',
            icon: 'error'
          })
          return;
        }
        else{
          var data = querySnapshot.docs.map(element => {
            var item = element.data() as any;
            item.id = element.id;
            this.currentUser = element.data() as any;
            this.currentUser.id = element.id
            return item;
          })
          // Add document ID to currentUser

          console.log(this.currentUser, "ADMIN ROLE");

          // this.SubscriptionGuard.checkSubscription(this.currentUser.clientid).then((res: any) => {
          //   const expire = res.isExpired;
          //   if (!expire) {
              localStorage.setItem("currentuser", JSON.stringify(this.currentUser));
              this.setUserStatus(this.currentUser);
              if (this.currentUser.passwordChanged) {
                // Navigate based on user role
                if (this.currentUser.role == "cluster" || this.currentUser.role == "ceo") {
                  this.router.navigateByUrl("/dashboard");
                  this.LoaderServiceService.hide(); 

                } else if (this.currentUser.role == "assign") {
                  this.router.navigateByUrl("/assignuserdashboard");
                  this.LoaderServiceService.hide(); 

                } else if (this.currentUser.role == "gm") {
                  this.router.navigateByUrl("/dashboard-gm");
                  this.LoaderServiceService.hide(); 

                } else if (this.currentUser.role == "auditer") {
                  this.router.navigateByUrl("/dashboard-auditer");
                  this.LoaderServiceService.hide(); 

                }
                else if (this.currentUser.role == "measurer") {
                  this.router.navigateByUrl("/measurerdashboard");
                  this.LoaderServiceService.hide(); 

                }
                else {
                  this.router.navigate(["/admindashboard"]);
                  this.LoaderServiceService.hide(); 

                }

              }
              else {
                this.router.navigate(["/change-password"]);
                this.LoaderServiceService.hide(); 

              }
        }
        // If user exists, proceed with Firebase Authentication
       
      })
      .catch((error) => {
        this.LoaderServiceService.hide(); // Show the loader before making the request
        throw error;
      });
      // Fetch user data after successful authentication
      // this.firestore.collection("users").ref.where("email", "==", user.email)
      //   .onSnapshot((snap) => {
      //     snap.forEach((userRef) => {
       

      //         // }
      //         // else {
      //         //   alert("Subscribtion Expired")
      //         //   this.logout()
      //         // }
      //       // })



      //     });
      //   });

    })
    .catch((error) => {
      
      this.LoaderServiceService.hide(); // Show the loader before making the request
throw error;
    });
    // Check if the email exists in the Firestore collection
   
  }


  forgetpassword(email: string) {
    this.LoaderServiceService.show(); // Show the loader before making the request

    this.afAuth.sendPasswordResetEmail(email)
      .then((value) => {
        alert("please check your email");
        this.ngzone.run(() => {
          this.router.navigateByUrl("/login");
        });
      })
      .catch((err) => {
        console.log("Something went wrong: ", err.message);
      });
    this.LoaderServiceService.hide(); // Show the loader before making the request

  }

  async emailSignup(email: string, password: string) {
    // var authApp = firebase.initializeApp(
    //   environment.firebaseConfig
    //       , 'authApp');
    //   var detachedAuth = authApp.auth();
    this.LoaderServiceService.show(); // Show the loader before making the request
    return new Promise<any>((resolve, reject) => {
  const databaseName=  localStorage.getItem('DbName')
   console.log(databaseName);
      this.createUser(email, password, databaseName)
      .subscribe(response => {
        console.log('User created successfully:', response);

        resolve(response);
        // Show the loader before making the request

      }, error => {
        console.error('Error creating user:', error);
        this.LoaderServiceService.hide(); // Show the loader before making the request

      });

    });

  }
  createUser(email: string, password: string, databaseName: string): Observable<any> {
    const url = 'https://createuserwithcustomclaim-tccea3va2a-uc.a.run.app/createUser'; // Replace with your function's URL
    const userData = { email, password, databaseName };
    return this.http.post(url, userData);
  }



  adduser(user) {
    this.LoaderServiceService.show(); // Show the loader before making the request

    this.firestore.collection("users").add(user)
      .then((value) => {
        this.LoaderServiceService.hide(); // Show the loader before making the request

      })
      .catch((error) => {
        this.LoaderServiceService.hide(); // Show the loader before making the request
        console.log("Something went wrong: ", error);
      });
  }
  resetPassword(email: string): Promise<void> {
    this.LoaderServiceService.show(); // Show the loader before making the request

    return this.afAuth.sendPasswordResetEmail(email)
      .then(() => {
        console.log('Password reset email sent');
        this.LoaderServiceService.hide(); // Show the loader before making the request

      })
      .catch((error) => {
        this.LoaderServiceService.hide(); // Show the loader before making the request

        console.error('Error sending password reset email', error);
      });
  }
  logout() {
    this.LoaderServiceService.show(); // Show the loader before making the request

    this.afAuth.signOut().then(() => {
      this.ngzone.run(() => {
        localStorage.clear()
        this.LoaderServiceService.hide();

        // Show the loader before making the request
        this.router.navigate(["/login"]);
      });
    });





  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider);
  }
  testempty() {
    console.log("i am here ");
  }
}
