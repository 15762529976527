import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root' // This means the service is provided at the root level
})
export class AddLoginService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    
    const expectedRole = route.data.expectedRole;
    const role = localStorage.getItem('role');

    if (!this.isAuthenticated() || role !== expectedRole) {
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return !!token; // Using !! to convert truthy/falsy to boolean
  }

}
